import React from "react"
import Fade from "react-reveal/Fade"

import Layout from "../components/layout"
import SEO from "../components/seo"
import supportMail from "../images/support-email.svg"
import supportChat from "../images/support-chat.svg"
import supportPhone from "../images/support-phone.svg"
import customSupport from "../images/customSupport.svg"
import { PopupText } from "react-calendly"

function openNav() {
  const navigationWindow = document.getElementById("myNav")
  const overlayContent = document.getElementById("overlayContent")
  navigationWindow.style.opacity = 1
  navigationWindow.style.transitionDelay = "0s"
  navigationWindow.style.visibility = "visible"
  overlayContent.style.opacity = 1
  overlayContent.style.transitionDelay = ".5s"
}

const Soporte = () => (
  <Layout>
    <SEO title="Soporte" />
    <div className="hero is-medium">
      <div className="hero-body mainHero">
        <div className="container support">
          <Fade delay={200}>
            <h3 className="support__title">
              ¿Quieres saber más?
              <br /> Siempre nos puedes contactar
            </h3>
          </Fade>
          <div className="columns">
            <div className="column">
              <div className="support__card">
                <Fade delay={400}>
                  <img src={supportMail} className="support__card-icon" />
                  <h3 className="support__card-subtitle">Email</h3>
                  <p className="support__card-text">
                    Escribe tus dudas y te ayudaremos
                  </p>
                  <a
                    className="support__card-cta"
                    href="mailto:ventas@bitcrm.mx"
                  >
                    Enviar Correo
                  </a>
                </Fade>
              </div>
            </div>
            <div className="column">
              <div className="support__card">
                <Fade delay={600}>
                  <img src={supportChat} className="support__card-icon" />
                  <h3 className="support__card-subtitle">Chat</h3>
                  <p className="support__card-text">
                    Lunes a Viernes de 9:00 a 6:00
                  </p>
                  <a
                    className="support__card-cta"
                    href="https://wa.me/5218181861848"
                    target="_blank"
                  >
                    Chatea con nosotros
                  </a>
                </Fade>
              </div>
            </div>
            <div className="column">
              <div className="support__card">
                <Fade delay={800}>
                  <img src={supportPhone} className="support__card-icon" />
                  <h3 className="support__card-subtitle">Teléfono</h3>
                  <p className="support__card-text">
                    Lunes a Viernes de 9:00 a 6:00
                  </p>
                  <div>
                    <a className="support__card-cta" href="tel:8196277721">
                      +52 (81) 9627 7721
                    </a>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="hero is-medium">
      <div className="hero-about hero-cover">
        <div className="container has-text-centered custom-support">
          <div className="columns">
            <div className="column">
              <Fade left delay={200}>
                <div className="custom-support__title">
                  <div>
                    <h3>
                      Agrega funcionalidades
                      <br /> personalizadas
                    </h3>
                    <br />
                    <div className="custom-support__cta">
                      <button className="button btn-alpha" onClick={openNav}>
                        <strong>Solicita un demo</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="column">
              <Fade right delay={200}>
                <img className="custom-support__img" src={customSupport} />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Soporte
